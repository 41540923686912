var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-5 mx-sm-5 mt-10",
    attrs: {
      "flat": ""
    }
  }, [_vm.supplierRolesErrorMessage ? _c('v-alert', {
    staticClass: "mt-4 col-12 text-center",
    attrs: {
      "dismissible": "",
      "text": "",
      "outlined": "",
      "type": "error"
    }
  }, [_vm._v(" " + _vm._s(_vm.supplierRolesErrorMessage) + " ")]) : _vm._e(), _c('v-card-title', [_vm._v(_vm._s(_vm.$t("ManageSupplierUserSecurityAccess")))]), _c('v-card-text', [_c('v-text-field', {
    ref: "inviteEmailSupplierUserForm",
    attrs: {
      "append-icon": "fa-plus-circle",
      "label": "E-mail",
      "type": "email",
      "rules": [function (value) {
        return !!value.trim() || _vm.$t('nameRules');
      }, function (value) {
        return _vm.isEmail(value) || _vm.$t('PleaseEnterAValidEmail');
      }, function (value) {
        return !_vm.isSupplierUserAlreadyExist(value) || _vm.$t('EmailDuplicatedInList');
      }],
      "outlined": ""
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.inviteSupplierUser.apply(null, arguments);
      },
      "click:append": _vm.inviteSupplierUser
    },
    model: {
      value: _vm.inviteEmailSupplierUser,
      callback: function ($$v) {
        _vm.inviteEmailSupplierUser = $$v;
      },
      expression: "inviteEmailSupplierUser"
    }
  }), _c('v-divider')], 1), _c('v-card-title', [_c('v-spacer'), _c('v-spacer'), _c('v-text-field', {
    attrs: {
      "append-icon": "fas fa-search",
      "label": "Search",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchSupplierRoles,
      callback: function ($$v) {
        _vm.searchSupplierRoles = $$v;
      },
      expression: "searchSupplierRoles"
    }
  })], 1), _c('v-card-text', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headersSupplierRoles,
      "items": _vm.supplierRoles,
      "search": _vm.searchSupplierRoles,
      "custom-filter": _vm.customFilter,
      "footer-props": {
        itemsPerPageOptions: [15, 50, 100, -1]
      }
    },
    scopedSlots: _vm._u([{
      key: `item.user`,
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('router-link', {
          staticClass: "text-decoration-none",
          attrs: {
            "to": {
              name: 'User',
              params: {
                userUUID: `${item._id}`
              }
            }
          }
        }, [item.firstname !== ' ' || item.lastname !== ' ' ? _c('span', [_vm._v(" " + _vm._s(item.firstname) + " - "), _c('strong', [_vm._v(_vm._s(item.lastname))])]) : _c('span', [_c('v-icon', {
          attrs: {
            "x-small": ""
          }
        }, [_vm._v("fas fa-pen")]), _vm._v(" " + _vm._s(_vm.$t("CompleteTheProfile")))], 1)]), _c('span', [_c('br'), _vm._v(_vm._s(item.email))])];
      }
    }, {
      key: `item.admin`,
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('v-switch', {
          attrs: {
            "color": "success",
            "inset": ""
          },
          on: {
            "change": function ($event) {
              _vm.roleModified = true;
            }
          },
          model: {
            value: item.admin,
            callback: function ($$v) {
              _vm.$set(item, "admin", $$v);
            },
            expression: "item.admin"
          }
        })];
      }
    }, {
      key: `item.access`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('v-switch', {
          attrs: {
            "color": "success",
            "inset": ""
          },
          on: {
            "change": function ($event) {
              _vm.roleModified = true;
            }
          },
          model: {
            value: item.access,
            callback: function ($$v) {
              _vm.$set(item, "access", $$v);
            },
            expression: "item.access"
          }
        })];
      }
    }], null, true)
  })], 1), _c('div', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "title": _vm.$t('Cancel')
    },
    on: {
      "click": _vm.getSupplierRoles
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "disabled": !_vm.roleModified,
      "color": "success",
      "title": _vm.$t('valid')
    },
    on: {
      "click": _vm.submitSupplierAccessSecurity
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fas fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('valid')) + " ")], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }