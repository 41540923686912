<template>
  <v-card flat class="px-5 mx-sm-5 mt-10">
    <v-alert
      dismissible
      text
      outlined
      type="error"
      v-if="supplierRolesErrorMessage"
      class="mt-4 col-12 text-center"
    >
      {{ supplierRolesErrorMessage }}
    </v-alert>
    <v-card-title>{{ $t("ManageSupplierUserSecurityAccess") }}</v-card-title>
    <v-card-text>
      <v-text-field
        v-model="inviteEmailSupplierUser"
        append-icon="fa-plus-circle"
        label="E-mail"
        type="email"
        ref="inviteEmailSupplierUserForm"
        :rules="[
          (value) => !!value.trim() || $t('nameRules'),
          (value) => isEmail(value) || $t('PleaseEnterAValidEmail'),
          (value) =>
            !isSupplierUserAlreadyExist(value) || $t('EmailDuplicatedInList'),
        ]"
        @keyup.enter="inviteSupplierUser"
        outlined
        @click:append="inviteSupplierUser"
      ></v-text-field>
      <v-divider></v-divider>
    </v-card-text>
    <v-card-title>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="searchSupplierRoles"
        append-icon="fas fa-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headersSupplierRoles"
        :items="supplierRoles"
        :search="searchSupplierRoles"
        :custom-filter="customFilter"
        :footer-props="{
          itemsPerPageOptions: [15, 50, 100, -1],
        }"
      >
        <template v-slot:[`item.user`]="{ item }">
          <router-link
            :to="{name: 'User', params: { userUUID: `${item._id}`}}"
            class="text-decoration-none"
          >
            <span v-if="item.firstname !== ' ' || item.lastname !== ' '">
              {{ item.firstname }} - <strong>{{ item.lastname }}</strong></span>
            <span v-else>
              <v-icon x-small>fas fa-pen</v-icon>
              {{ $t("CompleteTheProfile") }}</span>
          </router-link>
          <span> <br>{{ item.email }}</span>
        </template>
        <template v-slot:[`item.admin`]="{ item }">
          <v-switch
            v-model="item.admin"
            color="success"
            inset
            @change="roleModified = true"
          ></v-switch>
        </template>
        <template v-slot:[`item.access`]="{ item }">
          <v-switch
            v-model="item.access"
            color="success"
            inset
            @change="roleModified = true"
          ></v-switch>
        </template>
      </v-data-table>
    </v-card-text>
    <div>
      <v-btn
        color="error"
        class="text-none mr-2"
        :title="$t('Cancel')"
        @click="getSupplierRoles"
      >
        <v-icon small class="mr-1">
          fas fa-ban
        </v-icon>
        {{ $t('Cancel') }}
      </v-btn>
      <v-btn
        :disabled="!roleModified"

        color="success"
        class="text-none"
        @click="submitSupplierAccessSecurity"
        :title="$t('valid')"
      >
        <v-icon small class="mr-1">
          fas fa-check
        </v-icon>
        {{ $t('valid') }}
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable } from '@cloudmanufacturingtechnologies/portal-components';
import isEmail from 'validator/lib/isEmail';

const i18nData = require('./pageSupplierUserAndSecurity.i18n.json');

export default {
  name: 'PageSupplierUserAndSecurity',
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      inviteEmailSupplierUser: '',
      searchSupplierRoles: '',
      searchPrinters: '',
      searchCustomLocation: '',
      headersSupplierRoles: [],
      supplierRoles: [],
      supplierRolesErrorMessage: null,
      roleModified: false,
      isEmail,
    };
  },
  created() {
    /**
     * GET SUPPLIER
     */
    this.getSupplierRoles();
  },
  mounted() {
    this.headersSupplierRoles = [
      { text: this.$t('user'), value: 'user' },
      { text: this.$t('admin'), value: 'admin' },
      { text: this.$t('access'), value: 'access' },
    ];
  },
  methods: {
    customFilter(value, search, item) {
      if (!search) return true;
      search = search.toString().toLowerCase();
      if ((item._id + ' ' + item.email + ' ' + item.firstname + ' ' + item.lastname + ' ' + item.firstname).toString().toLowerCase().includes(search)) return true;
      return CustomFilterForSearchDatatable.customFilter(value, search, this);
    },
    /**
     * GET SUPPLIER ROLES
     */
    getSupplierRoles() {
      this.$apiInstance.getSupplierRoles(this.$route.params.supplierUUID).then(
        (supplierRolesData) => {
          this.supplierRolesData = supplierRolesData;
          this.roleModified = false;
          this.parseSupplierRoles(supplierRolesData);
        },

        (error) => {
          /**
           * ERROR GET SUPPLIER ROLES
           */
          this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
        }
      );
    },

    /**
     * PARSING BRAND ROLES
     */
    parseSupplierRoles(supplierRolesData) {
      const supplierRolesDataCopy = JSON.parse(
        JSON.stringify(supplierRolesData)
      );
      const supplierRolesArray = [];
      for (const roleUserMapping of supplierRolesDataCopy.access) {
        if (!(roleUserMapping._id in supplierRolesArray)) {
          supplierRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        supplierRolesArray[roleUserMapping._id].access = true;
      }
      for (const roleUserMapping of supplierRolesDataCopy.admin) {
        if (!(roleUserMapping._id in supplierRolesArray)) {
          supplierRolesArray[roleUserMapping._id] = roleUserMapping;
        }
        supplierRolesArray[roleUserMapping._id].admin = true;
      }
      const supplierRoles = [];
      for (const userId in supplierRolesArray) {
        if (!supplierRolesArray[userId].access) {
          supplierRolesArray[userId].access = false;
        }
        if (!supplierRolesArray[userId].admin) {
          supplierRolesArray[userId].admin = false;
        }
        supplierRoles.push(supplierRolesArray[userId]);
      }
      this.supplierRoles = supplierRoles;
    },
    /**
     * CHECK IF USER EXIT IN THIS SUPPLIER
     */
    isSupplierUserAlreadyExist(email) {
      let found = false;
      this.supplierRoles.forEach((user) => {
        if (user.email.toLowerCase() === email.toLowerCase()) {
          found = true;
          return found;
        }
      });
      return found;
    },
    /**
     * INVITE USER TO THIS SUPPLIER
     */
    inviteSupplierUser() {
      if (this.$refs.inviteEmailSupplierUserForm.validate(true)) {
        const inviteSupplierUserBody = new this.$BcmModel.InviteSupplierUserBody(
          this.inviteEmailSupplierUser
        );
        this.$apiInstance
          .inviteSupplierUser(
            this.$route.params.supplierUUID,
            inviteSupplierUserBody
          )
          .then(
            (data) => {
              this.getSupplierRoles();
              this.inviteEmailSupplierUser = '';
              this.$notification.notify('SUCCESS',this.$t('HasBeenSuccessfullyAdded', { email: data.email }));
            },
            (error) => {
              /**
               * ERROR INVITE BRAND USER
               */
              this.globalErrorMessage = ApiErrorParser.parse(error);
            }
          );
      }
    },
    /**
     * SUBMIT BRAND ROLES ACCESS / ADMIN
     */
    submitSupplierAccessSecurity() {
      const rolesUserMapping = new this.$BcmModel.Roles();
      for (const role in rolesUserMapping) {
        rolesUserMapping[role] = [];
      }
      for (const userMapping of this.supplierRoles) {
        if (userMapping.access) {
          rolesUserMapping.access.push(userMapping._id);
        }
        if (userMapping.admin) {
          rolesUserMapping.admin.push(userMapping._id);
        }
      }
      this.$apiInstance
        .modifySupplierRoles(this.$route.params.supplierUUID, rolesUserMapping)
        .then(
          (data) => {
            this.supplierRolesData = data;
            this.parseSupplierRoles(this.supplierRolesData);
            this.roleModified = false;
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('RoleSuccessfullyModified'));
          },
          (error) => {
            /**
             * ERROR SAVE BRAND ROLES
             */
            this.supplierRolesErrorMessage = ApiErrorParser.parse(error);
          }
        );
    },
    /**
     * RESET BRAND ROLES ACCESS / ADMIN
     */
    cancelSupplierAccessSecurity() {
      this.parseSupplierRoles(this.supplierRolesData);
      this.roleModified = false;
    },
  },
};
</script>
